/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration

$primary: #E51B1A !default;
$light: #F8F9FB !default;
$warning: #d3830c !default;


// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack


// scss-docs-end import-stack

@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "./fonts.scss";

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

* {
  font-family: "Helvetica Neue", Arial, sans-serif;
}


h1,
h2,
h3,
h4 {
  font-family: "Jost";
}

.profile-area {
  width: 300px;
}

.bg-red {
  background: #E51B1A;
}

.logo {
  max-width: 100px;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.cursor-na {
  cursor: not-allowed;
  user-select: none;
}

.slot-type-card {
  height: 54px;
}

.toolbar {
  height: 50px;
  padding-top: 10px;
  position: fixed;
  background: #f8f9fb;
  position: relative;
  top: 0px;
  z-index: 900;
  width: 100vw;
  border-bottom: 1px rgba(0,0,0,.2)solid;
}

.slotfield-full {
  height: 80px;
  font-size: 12px;
  transition: 300ms;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
}

.username {
  width: 50% !important;
  text-align: right !important;
}

.slotfield-half {
  height: 102px;
  text-align: center;
}

.toolbar input {
  @extend .btn;
  @extend .btn-sm;
  @extend .border;
  background-color: white;
  user-select: auto;
  cursor: text;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.navbar {
  height: 56px;
}

.hover {
  transition: 300ms;
}

.nav-item a {
  font-family: "Jost";
  font-size: 18px;
  font-weight: 600;
  margin-top: 3px;
}

.hover:hover {
  opacity: 0.4;
}

.popup-content {
  max-width: 756px !important;
  min-width: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80%;
  border: 0px;
}

.navbar .popup-content {
  max-width: 150px !important;
  min-width: 50px !important;
}

.filterActive {
  opacity: 1;
}

.filterInactive {
  opacity: 0.3;
}

.control-mode-icon {
  margin-right: 10px;
  margin-left: 10px;
}

.syncSpinner {
  transition: 500ms;
  opacity: 0;
}

.spin {
  transform: rotate(360deg);
  opacity: 1;
}

[draggable=true] {
  cursor: move;
}

body {
  background: #ffffff;
}

.card {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, .75);
}

.exp {
  border-left-width: 4px !important;
}

.slot-simple,
.slot-trello {
  border: 1px #454545 solid;
  color: #454545 !important;
}

.slot-weclapp {
  border: 1px #454545 solid;
  color: #454545 !important;
  background-image: url(../assets/weclapp-logo.png) !important;
  background-size: 10%;
  background-repeat: no-repeat;
  background-position: 96% 90%;
}

.slot-weclapp:hover {
  border: 1px #454545 solid;
  color: #454545 !important;
  background-color: #454545 !important;
  color: white !important;
  background-image: none;
  background-size: 10%;
  background-repeat: no-repeat;
  background-position: 96% 90%;
}

.slot-simple:hover,
.slot-trello:hover {
  background: #454545 !important;
  color: white !important;
}



.slot-container {
  backdrop-filter: blur(10px);
  color: white;
  width: 100%;
  height: 56px;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .d-flex;
  transition: 200ms !important;
  font-weight: 600 !important;
  hyphens: auto;
  border-radius: 2px;
  border-width: 1px;
}

.slot-container small {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: "Jost";
}

.slot-container:hover {
  background-color: #E51B1A;
  backdrop-filter: blur(10px);
  color: white !important;
}

.slot-sick {
  border: 1px #E51B1A solid;
  background: transparent;
  backdrop-filter: blur(10px);
  color: #E51B1A !important;
}

.opacity-0 {
  opacity: 0;
}

.avatar {
  transition: 250ms;
}

.offline {
  filter: grayscale(100%);
}

.slot-holiday,
.slot-school {
  border: 1px #E51B1A solid;
  backdrop-filter: blur(10px);
  color: #E51B1A !important;

}

.slot-holiday:hover,
.slot-school:hover {
  border: 1px #E51B1A solid;
  backdrop-filter: blur(10px);
  background: #E51B1A !important;
  color: white
}

.slot-support {
  border: 1px #67A63E solid;
  backdrop-filter: blur(10px);
  color: #67A63E;
}

.slot-support:hover {
  background: #67A63E;
  backdrop-filter: blur(10px);
  color: white;
}


a {
  text-decoration: none;
}

.text-white {
  color: white;
}

.bg-today {
  background: #e3e3e3;
}

.avatar {
  border-radius: 100%;
}

.react-toast-notifications__container {
  margin-top: 62px;
}

.sharkif {
  display: block;
  width: 100%;
}

.popuup-arrow {
  display: none;
}

.slot-header-odd {
  transition: 300ms;
}

.slot-header-even {
  transition: 300ms;
}

.avatar-container {
  position: relative;
  height: 40px;

}

.avatar-container img {
  position: absolute;
  top: 5px;
  right: 0px;
}

.avatar-container .status {
  background: white;
  border-radius: 25px;
  width: 24px;
  height: 24px;
  z-index: 50;
  position: absolute;
  left:-47px;
  top: -3px;
font-size: 13px;
}

.avatar-container.active {
  color: #04A777 !important;
}

.avatar-container.notactive {
  color: #E51B1A !important;
}

.slot-body-odd {
  background: #F8F9FB;
  transition: 300ms;
}

.slot-body-even {
  background: #ffffff;
  transition: 300ms;
}

.slot-header-user {
  background: #CE1817;
  transition: 300ms;
}

.col-today.slot-body-even {
  background: #FBF2F3;
  transition: 300ms;
}

.col-today.slot-body-odd {
  background: #FBF2F3;
  transition: 300ms;
}

.col-today .slot-header-even {
  background: #F4E8E8;
  transition: 300ms;
}

.col-today .slot-header-odd {
  transition: 300ms;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

ul.bullet {
  padding-left: 15px;
}

.popup-overlay {
  background: rgba(0, 0, 0, .2) !important;
  backdrop-filter: blur(2px) !important;
  border-radius: 2px;
}

ul.bullet li {
  list-style: square;
  margin-bottom: 10px;
}

.ultra-small {
  font-size: 10px;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 4s linear infinite;
}

.rotate-center {
  transform-origin: 50% 50%;
}

i {
  width: 14px;
  height: 14px;
}

.icon-plus {
  opacity: .3;
}

.slot-header {
  height: 77px;
}

.slot-description-parent {
  overflow: hidden;
  max-height: 200px;
  box-shadow: inset 0px -11px 8px -10px #959595;
  opacity: .7;
}

.show-full-description {
  max-height: max-content !important;
  box-shadow: none;
  opacity: 1;
}

.scroll-y-overflow-content {
  overflow-y: scroll !important;
}

.slot-description-parent h1 {
  font-size: 18px;
  font-weight: bold;
}

input,
textarea {
  box-shadow: none !important;
}

.slot-muted {
  opacity: .1;
  transition: 300ms;
}

.opacity-50 {
  opacity: .5;
}

.weclapp-autocomplete-wrap {
  height: 72px;
  overflow-x: hidden;
}

.bg-on-hover:hover {
  background-color: $light;
}

.has-expenses{
  border-right-width: 4px;
} 

.border-top-thick{
  border-top: 2px black solid !important;
}

.expand-absolute {
  position: absolute;
  left: 0px;
  right: 0px; 
}

.border-bottom-thick{
  border-bottom: 2px black solid !important;
}

.bg-tr-white {
  background: white;
}

progress {
  accent-color: $primary !important;
}

.extra-small-tabletext {
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
}

.expense-bucket {
  top: 62px;
  bottom: 7px;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  top: 0px;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 9000;
}

.load-elipsis {
  /* change color here */
  color: $secondary;
}
.load-elipsis,
.load-elipsis div {
  box-sizing: border-box;
}
.load-elipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 12.35px;
}
.load-elipsis div {
  position: absolute;
  top: 3px;
  width: 7.33px;
  height: 7.33px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.load-elipsis div:nth-child(1) {
  left: 8px;
  animation: load-elipsis1 0.6s infinite;
}
.load-elipsis div:nth-child(2) {
  left: 8px;
  animation: load-elipsis2 0.6s infinite;
}
.load-elipsis div:nth-child(3) {
  left: 32px;
  animation: load-elipsis2 0.6s infinite;
}
.load-elipsis div:nth-child(4) {
  left: 56px;
  animation: load-elipsis3 0.6s infinite;
}
@keyframes load-elipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes load-elipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes load-elipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.badge {
  @extend .border-0;
}

